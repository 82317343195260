import { useState, useEffect, useCallback, useRef } from "react";
import Image from 'next/image';
import useEmblaCarousel from "embla-carousel-react";
import AutoHeight from 'embla-carousel-auto-height';
import Autoplay from "embla-carousel-autoplay";
import styles from './carousels.module.scss';
import Icon from '@components/Icons';

import type { carouselImage } from '@lib/types';

/**
 * Basic image carousel with controls
 * @param images carouselImage Array to be displayed
 * @param overlap top | bottom | none - string adjust margin to overlap sections
 * @returns Embla image carousel Element with controls
 */
const CarouselBasic = ({ images, overlap = "none" }: {
    images: carouselImage[], // Array of Carousel Images
    overlap?: string //adjust margin to overlap sections
}) => {

    const autoplay = useRef(
        Autoplay(
            { delay: 8000, stopOnInteraction: false },
            //@ts-ignore
            (emblaRoot) => emblaRoot.parentElement
        )
    );

    // Carousel basic properties
    const [viewportRef, embla] = useEmblaCarousel(
        {
            loop: true,
            skipSnaps: true,
        },
        [AutoHeight(), autoplay.current]
    );

    // initialising button states
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[] | never[]>([]);

    // Initialising interactions
    const scrollPrev = useCallback(() => {
        if (!embla) return;
        embla.scrollPrev();
        autoplay.current.reset();
    }, [embla]);

    const scrollNext = useCallback(() => {
        if (!embla) return;
        embla.scrollNext();
        autoplay.current.reset();
    }, [embla]);

    const scrollTo = useCallback((index) => {
        if (!embla) return;
        embla.scrollTo(index);
        autoplay.current.reset();
    }, [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    // initialising use effect
    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <>
            {/* If only one image just show image and not carousel */
                (images.length <= 1) ?
                    <div className={`${styles.embla} ${styles[overlap]}`} style={{width: '100%', maxWidth: `${images[0].width}px`}}>
                        <Image
                            loading="lazy"
                            src={images[0].path}
                            width={images[0].width}
                            height={images[0].height}
                            alt={images[0].alt}
                            placeholder='blur'
                            blurDataURL={images[0].blurDataURL}
                            className={styles.img}
                            quality={100}
                            sizes={images[0].sizes}
                        />
                    </div>
                    : /* if more than one image show carousel */
                    <div className={`${styles.embla} ${styles[overlap]}`} style={{width: '100%', maxWidth: `${images[0].width}px`}}>
                        <div className={`${styles.embla__viewport} ${styles.is_draggable}`} ref={viewportRef}>
                            <div className={styles.embla__container}>
                                {images.map((image, index) => (
                                    <div className={styles.embla__slide} key={index}>
                                        <div className={styles.embla__slide__inner}>
                                            <div className={styles.embla__slide__img}>
                                                <Image
                                                    loading="lazy"
                                                    src={image.path}
                                                    width={image.width}
                                                    height={image.height}                                                    
                                                    alt={image.alt}
                                                    placeholder='blur'
                                                    blurDataURL={image.blurDataURL}
                                                    className={styles.img}
                                                    quality={100}
                                                    sizes={images[0].sizes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.embla__dots}>
                                {scrollSnaps.map((_: any, index: any) => (
                                    <button
                                        key={index}
                                        className={`${styles.embla__dot} ${index === selectedIndex ? styles.is_selected : ""}`}
                                        type="button"
                                        onClick={() => scrollTo(index)}
                                    />
                                ))}
                            </div>
                        </div>
                        <button
                            className={`${styles.embla__button} ${styles.embla__button_prev}`}
                            onClick={scrollPrev}
                        >
                            <Icon iconClass="nav-arrow-left" extraClass={styles.embla__button__svg} />
                        </button>
                        <button
                            className={`${styles.embla__button} ${styles.embla__button_next}`}
                            onClick={scrollNext}
                        >
                            <Icon iconClass="nav-arrow-right" extraClass={styles.embla__button__svg} />
                        </button>
                    </div>
        /* End image vs carousel */}
        </>
    );
}

export default CarouselBasic;