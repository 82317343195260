import { useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoHeight from 'embla-carousel-auto-height';
import Autoplay from "embla-carousel-autoplay";
import styles from './carousels.module.scss';
import type { carouselImage } from '@lib/types';
import Icon from '@components/Icons';
import Image from "next/image";

/**
 * Cabin Content Embla Carousel - Built so that controls could be added to the images for Terri.
 * @param children react child section elements (minus the images)
 * @param images carouselImage[] of the individual image for each slide
 * @param overlap top | bottom | none - string adjust margin to overlap sections
 * @returns Embla carousel of child elements
 */
const CarouselCabinContent = ({ children, images, overlap = "none" }: {
    children: any, // section content
    images: carouselImage[] // images
    overlap?: string, //adjust margin to overlap sections
}) => {

    const autoplay = useRef(
        Autoplay(
            { delay: 12000, stopOnInteraction: true },
            //@ts-ignore
            (emblaRoot) => emblaRoot.parentElement
        )
    );

    // Carousel basic properties
    const [viewportRef, embla] = useEmblaCarousel(
        {
            loop: true,
            skipSnaps: true,
        },
        [AutoHeight(), autoplay.current]
    );

    // initialising button states
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[] | never[]>([]);

    // Initialising interactions
    const scrollPrev = useCallback(() => {
        if (!embla) return;
        embla.scrollPrev();
        autoplay.current.reset();
    }, [embla]);

    const scrollNext = useCallback(() => {
        if (!embla) return;
        embla.scrollNext();
        autoplay.current.reset();
    }, [embla]);

    const scrollTo = useCallback((index) => {
        if (!embla) return;
        embla.scrollTo(index);
        autoplay.current.reset();
    }, [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    // initialising use effect
    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla, setScrollSnaps, onSelect]);


    return (
        <>

            <div className={`${styles.embla} ${styles[overlap]}`}>
                <div className={`${styles.embla__viewport} ${styles.is_draggable}`} ref={viewportRef}>
                    <div className={styles.embla__container}>
                        {children.map((content, index) => (
                            <div className={styles.embla__slide} key={index}>
                                <div className={`${styles.embla__slide__inner} ${styles.contentflex}`}>

                                    <div className={`${styles.embla}`} style={{ width: '100%', maxWidth: `${images[index].width}px` }}>
                                        <div className={`${styles.embla__viewport} ${styles.is_draggable}`} ref={viewportRef}>
                                            <div className={styles.embla__container}>
                                                <Image
                                                    loading="lazy"
                                                    src={images[index].path}
                                                    width={images[index].width}
                                                    height={images[index].height}
                                                    alt={images[index].alt}
                                                    placeholder='blur'
                                                    blurDataURL={images[index].blurDataURL}
                                                    className={styles.img}
                                                    quality={100}
                                                    sizes={images[0].sizes}
                                                />
                                            </div>
                                            <div className={styles.embla__dots}>
                                                {scrollSnaps.map((_: any, index: any) => (
                                                    <button
                                                        key={index}
                                                        className={`${styles.embla__dot} ${index === selectedIndex ? styles.is_selected : ""}`}
                                                        type="button"
                                                        onClick={() => scrollTo(index)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <button
                                            className={`${styles.embla__button} ${styles.embla__button_prev}`}
                                            onClick={scrollPrev}
                                        >
                                            <Icon iconClass="nav-arrow-left" extraClass={styles.embla__button__svg} />
                                        </button>
                                        <button
                                            className={`${styles.embla__button} ${styles.embla__button_next}`}
                                            onClick={scrollNext}
                                        >
                                            <Icon iconClass="nav-arrow-right" extraClass={styles.embla__button__svg} />
                                        </button>
                                    </div>


                                    {content}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CarouselCabinContent;