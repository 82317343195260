import { useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoHeight from 'embla-carousel-auto-height';
import Autoplay from "embla-carousel-autoplay";
import styles from './carousels.module.scss';

/**
 * Basic Conetent Embla Carousel
 * @param children react child elements
 * @param overlap top | bottom | none - string adjust margin to overlap sections
 * @returns Embla carousel of child elements
 */
const CarouselContent = ({ children, overlap = "none" }: {
    children: any, // Array of content 
    overlap?: string //adjust margin to overlap sections
}) => {

    const autoplay = useRef(
        Autoplay(
            { delay: 12000, stopOnInteraction: true },
            //@ts-ignore
            (emblaRoot) => emblaRoot.parentElement
        )
    );

    // Carousel basic properties
    const [viewportRef, embla] = useEmblaCarousel(
        {
            loop: true,
            skipSnaps: true,
        },
        [AutoHeight(), autoplay.current]
    );

    return (
        <>
            {(children.length <= 1) ? <> {children}</> :
                <div className={`${styles.embla} ${styles[overlap]}`}>
                    <div className={`${styles.embla__viewport} ${styles.is_draggable}`} ref={viewportRef}>
                        <div className={styles.embla__container}>
                            {children.map((content, index) => (
                                <div className={styles.embla__slide} key={index}>
                                    <div className={`${styles.embla__slide__inner} ${styles.contentflex}`}>
                                        {content}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>}
        </>
    );
}

export default CarouselContent;